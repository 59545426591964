<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignación Solicitudes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Asignación Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Origen -->

                  <!-- Funcionario -->
                  <div class="form-group col-md-6">
                    <label>Funcionario</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="funcionario"
                      placeholder="Funcionario"
                      label="nombres"
                      :options="listasForms.funcionarios"
                      @input="getIndex()"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="row m-4">
                <div class="col-md-12">
                  <div v-drag-and-drop:options="options">
                    <ul class="drag-list">
                      <li
                        class="drag-column"
                        v-for="group in groups"
                        :key="group.id"
                      >
                        <span class="drag-column-header">
                          <h2>{{ group.name }}</h2>
                          <VueFeather type="more-vertical"></VueFeather>
                        </span>
                        <vue-draggable-group
                          v-model="group.items"
                          :groups="groups"
                          :data-id="group.id"
                          @change="change"
                        >
                          <ul class="drag-inner-list" :data-id="group.id">
                            <li
                              class="drag-item"
                              style="height: auto"
                              v-for="item in group.items"
                              :key="item.id"
                              :data-id="item.id"
                            >
                              <div class="drag-item-text">
                                <!-- Usuario -->
                                <h6
                                  style="font-size: 14px"
                                  v-if="item.users.length > 0"
                                >
                                  <strong>Usuario: </strong
                                  >{{ item.users[0].name }}
                                </h6>
                                <h6 style="font-size: 14px" v-else>
                                  <span class="badge badge-danger"
                                    >No tiene Usuario</span
                                  >
                                </h6>
                                <!-- Origen -->
                                <h6
                                  style="font-size: 14px"
                                  v-if="
                                    item.sitio_origen_type ==
                                      'App\\GeocercaZona' &&
                                      item.sitio_origen != null
                                  "
                                >
                                  <strong>Origen: </strong>
                                  {{ item.sitio_origen.nombre }}
                                </h6>
                                <h6
                                  style="font-size: 14px"
                                  v-else-if="
                                    item.sitio_origen_type == 'App\\Ciudad' &&
                                      item.ciudad_origen != null
                                  "
                                >
                                  <strong>Origen: </strong>
                                  {{ item.ciudad_origen.nombre }}
                                </h6>
                                <h6 style="font-size: 14px" v-else>
                                  <strong>Origen: </strong>
                                  {{ item.direccion_origen }}
                                </h6>
                                <!-- Destino -->
                                <h6
                                  style="font-size: 14px"
                                  v-if="
                                    item.sitio_destino_type ==
                                      'App\\GeocercaZona' &&
                                      item.sitio_destino != null
                                  "
                                >
                                  <strong>Destino: </strong>
                                  {{ item.sitio_destino.nombre }}
                                </h6>
                                <h6
                                  style="font-size: 14px"
                                  v-else-if="
                                    item.sitio_destino_type == 'App\\Ciudad' &&
                                      item.ciudad_destino != null
                                  "
                                >
                                  <strong>Destino: </strong>
                                  {{ item.ciudad_destino.nombre }}
                                </h6>
                                <h6 style="font-size: 14px" v-else>
                                  <strong>Destino: </strong>
                                  {{ item.direccion_destino }}
                                </h6>
                                <!-- Tipo de Vehículo -->
                                <h6 style="font-size: 14px">
                                  <strong>Tipo Vehículo: </strong>
                                  {{ item.tipo_vehiculo.nombre }}
                                </h6>
                                <!-- Fecha Inicio Servicio -->
                                <h6 style="font-size: 14px">
                                  <strong>Fecha Inicio Servicio: </strong>
                                  {{ item.fecha_hora_inicio_servicio }}
                                </h6>
                                <!-- Fecha Fin Servicio -->
                                <h6 style="font-size: 14px">
                                  <strong>Fecha Fin Servicio: </strong>
                                  {{ item.fecha_hora_fin_servicio }}
                                </h6>
                                <div class="row">
                                  <div class="col-md-6 p-2">
                                    <div
                                      class="small-box bg-lightblue p-1"
                                      style="font-size: 14px"
                                      v-if="item.pasajeros.length > 0"
                                    >
                                      <strong>Pasajeros: </strong>
                                      <div
                                        v-for="pasajero in item.pasajeros"
                                        :key="pasajero.id"
                                      >
                                        {{ pasajero.nombres }}
                                        {{ pasajero.apellidos }}
                                      </div>
                                    </div>
                                    <div style="font-size: 14px" v-else>
                                      <span class="badge badge-danger"
                                        >No tiene Pasajeros</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </vue-draggable-group>
                        <div
                          class="card-footer"
                          v-if="group.id == 2 && group.items.length > 0"
                        >
                          <div class="row">
                            <div class="col-md-12 text-right">
                              <button
                                class="btn btn-sm bg-navy"
                                @click="edit(group.items)"
                              >
                                <i class="fas fa-check-circle"></i><br /><strong
                                  >Validar Solicitud
                                </strong>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer" v-else></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import VueFeather from "vue-feather";

export default {
  name: "SolicitudesNacionalesIndex",
  components: {
    vSelect,
    Loading,
    VueFeather,
  },
  data() {
    return {
      cargando: false,
      filtros: {},
      origen: [],
      destino: [],
      funcionario: null,
      solicitudes: {},
      arrayAntGroup: [],
      listasForms: {
        ciudades: [],
        sitios: [],
        sitio_types: [],
        funcionarios: [],
      },
      groups: [
        {
          id: 1,
          name: "Solicitudes Pendientes",
          items: [],
        },
        {
          id: 2,
          name: "Solicitud Final",
          items: [],
        },
      ],
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
      },
    };
  },

  methods: {
    getIndex() {
      this.cargando = true;
      // Se ajusta los parámetros del filtro
      this.filtros.user_id = null;
      if (this.funcionario) {
        this.filtros.user_id = this.funcionario.user_id;
      }

      this.filtros.origen_id = null;
      if (this.filtros.sitio_origen_type && this.origen) {
        this.filtros.origen_id = this.origen.id;
      }

      this.filtros.destino_id = null;
      if (this.filtros.sitio_destino_type && this.destino) {
        this.filtros.destino_id = this.destino.id;
      }

      axios
        .get("/api/tep/solicitudesNacionales", {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.groups[0].items = response.data;
          this.groups[1].items = [];
        });
    },

    async init() {
      await this.groups[0].items.unshift(this.arrayAntGroup[0]);
      this.arrayAntGroup = [];
    },

   /*  async change(evt) {
      let pasajeros = 0;
      for (let i = 0; i < evt[1].items.length; i++) {
        pasajeros += evt[1].items[i].pasajeros.length;
        if (i > 0) {
          if (
            evt[1].items[i - 1].tipo_vehiculo_id !=
            evt[1].items[i].tipo_vehiculo_id
          ) {
            await this.$swal({
              title: "El tipo de vehículo no es el mismo !!!.",
              text: "Que tipo de vehículos esta agregando en esta solicitud?",
              icon: "warning",
              showCancelButton: true,
              position: "top-end",
              toast: true,
              padding: "2em",
              background: "#fff url(/img/boxed-bg.png)",
              backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
              confirmButtonText: evt[1].items[i].tipo_vehiculo.nombre,
              cancelButtonText: evt[1].items[i - 1].tipo_vehiculo.nombre,
            }).then((result) => {
              if (result.value) {
                this.arrayAntGroup.push(this.groups[1].items[i - 1]);
                this.groups[1].items.splice(i - 1, 1);
              } else {
                this.arrayAntGroup.push(this.groups[1].items[i]);
                this.groups[1].items.splice(i, 1);
              }
            });
          } else if (
            pasajeros > evt[1].items[i].tipo_vehiculo.cantidad_pasajeros
          ) {
            await this.$swal({
              title: "Excedio la cantidad de pasajeros permitida",
              text:
                "La cantidad máxima de pasajeros para el tipo de vehiculo " +
                " " +
                evt[1].items[i].tipo_vehiculo.nombre +
                " " +
                "son" +
                " " +
                " " +
                evt[1].items[i].tipo_vehiculo.cantidad_pasajeros,
              icon: "warning",
              showConfirmButton: false,
              timer: 2000,
              backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            });
            this.arrayAntGroup.push(this.groups[1].items[i]);
            this.groups[1].items.splice(i, 1);
          }
        }
      }

      if (this.arrayAntGroup.length > 0) {
        await this.init();
      }
    },
 */
    edit(solicitudes) {
      return this.$router.push({
        name: "/Tep/SolicitudesNacionalesForm",
        params: {
          data_edit: solicitudes,
          id: solicitudes.id,
        },
      });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/basico/geocercaZona/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarCiudades() {
      let me = this;
      var url = "api/admin/ciudades/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ciudades = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSitioType() {
      axios.get("/api/lista/94").then((response) => {
        this.listasForms.sitio_types = response.data;
      });
    },

    getFuncionarios() {
      let me = this;
      var url = "api/admin/funcionarios/lista";
      axios
        .get(url, {
          params: {
            gerente_area: 1,
          },
        })
        .then(function(response) {
          me.listasForms.funcionarios = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    clearOrigen() {
      this.origen = null;
      this.getIndex();
    },

    clearDestino() {
      this.destino = null;
      this.getIndex();
    },
  },
  mounted() {
    this.getIndex();
    this.buscarSitios();
    this.buscarCiudades();
    this.getSitioType();
    this.getFuncionarios();
  },
};
</script>
<style >
/* $ease-out: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
$to-do: #f4ce46;
$in-progress: #2a92bf;
$approved: #00b961; */

.drag-list {
  display: flex;
  align-items: flex-start;
  font-size: 15px;

  @media (max-width: 800px) {
    display: none;
  }
}

.drag-column {
  flex: 1;
  margin: 0 10px;
  position: relative;
  background: rgba(black, 0.05);
  overflow: hidden;
  border-radius: 15px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px;
  user-select: none;
}

.drag-inner-list {
  height: 75vh;
  overflow: auto;
}

.drag-item {
  margin: 20px;
  height: auto;
  padding: 4px;
  background: rgba(black, 0.1);
  transition: $ease-out;
  border-radius: 15px;
  margin-left: auto;

  /* items grabbed state */
/*   &[aria-grabbed="true"] {
    background: linear-gradient(to bottom right, #ff1c79, #001871);
    color: #fff;
  } */

  .drag-item-text {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
}

.drag-header-more {
  cursor: pointer;
}

@keyframes nodeInserted {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0.6;
  }
}

.item-dropzone-area {
  height: 8rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 15px;
}
</style>
